// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'jquery';
import 'moment';
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';
import 'select2/dist/js/select2.full';
import 'inputmask/dist/jquery.inputmask';

import '../main/app';

Rails.start();
ActiveStorage.start();
