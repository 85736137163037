require('./theme');

jQuery(function ($) {
  $('.cep').inputmask('99999-999');
  $('.date-mask').inputmask('99/99/9999');
  $('.cpf').inputmask('999.999.999-99');
  $('.phone, #phone_alt').inputmask({
    mask: ['(99) 9999-9999', '(99) 99999-9999'],
    keepStatic: true,
  });

  $('.select2').select2();
  $('.select2-tags').select2({ tags: true });

  updatePaymentInputs(
    $('#fisherman_payment_method, #boat_payment_method').val()
  );
  $('#fisherman_payment_method, #boat_payment_method').on('input', (e) =>
    updatePaymentInputs(e.target.value)
  );

  $('.checkin_date').hide();
  $('.checkin_fishermen').hide();

  $('#checkin_boat_id').on('change', (e) => {
    $('#checkin_fisherman_ids option').each(function () {
      $(this).remove();
    });

    const boatId = e.target.value;
    if (!boatId) return;

    $.get(`/painel/barcos/${boatId}/pescadores`).done((data) => {
      data.map((fishermen) => {
        $('#checkin_fisherman_ids').append(
          new Option(fishermen.name, fishermen.id)
        );
      });
      $('.checkin_fishermen').show();
    });
    $.get(`/painel/barcos/${boatId}/diarias`).done((invalidDates) => {
      $('.datetimepicker').daterangepicker({
        singleDatePicker: true,
        locale: {
          format: 'DD/MM/YYYY',
        },
        isInvalidDate: function (date) {
          return invalidDates.includes(date.format('YYYY-MM-DD'));
        },
      });
      $('.checkin_date').show();
    });
  });
});

function updatePaymentInputs(value) {
  if (value === 'Pix') {
    $('.row-bank').hide();
    $('.row-pix').fadeIn();
  } else if (value === 'TED/DOC') {
    $('.row-pix').hide();
    $('.row-bank').fadeIn();
  } else {
    $('.row-pix').hide();
    $('.row-bank').hide();
  }
}
